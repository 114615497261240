<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <!-- <div class="row">
            <div class="col-md-12 d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title" v-if="this.propertyData.id == ''">{{
                                    $t('property_detail.Add_New_Property') }}</h4>
                                <h4 class="card-title" v-else>{{ $t('property_detail.Update_Property') }}</h4>
                            </div>
                            <div class="card-body">
                                <form class="forms-sample" @submit.stop.prevent="addProperty">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4">
                                            <b-form-group :label="$t('property_detail.Property_Type')"
                                                label-for="Property Type">
                                                <b-form-select name="propertyType" v-model="propertyData.propertyType"
                                                    :options="translatedPropertyTypeOptions"
                                                    :state="$v.propertyData.propertyType.$dirty ? !$v.propertyData.propertyType.$error : null"
                                                    id="propertyType" class="mb-3" value-field="propertyType"
                                                    text-field="propertyType">
                                                </b-form-select>

                                                <b-form-invalid-feedback id="input-1-live-feedback">This is a required
                                                    field.
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                            <b-form-group :label="$t('property_detail.wifi')" label-for="wifiAvailable">
                                                <b-form-select name="wifiAvailable" v-model="propertyData.wifiAvailable"
                                                    :options="translatedyesnoOptions" id="wifiAvailable" class="mb-3"
                                                    :state="$v.propertyData.wifiAvailable.$dirty ? !$v.propertyData.wifiAvailable.$error : null">
                                                    <template v-slot:first>
                                                        <option :value="null" disabled>Select</option>
                                                    </template>
</b-form-select>
<b-form-invalid-feedback v-if="!propertyData.wifiAvailable">
    This is a required field
</b-form-invalid-feedback>
</b-form-group>
<b-form-group :label="$t('property_detail.kitchen')" label-for="kitchen">
    <b-form-select name="kitchen" v-model="propertyData.kitchen" :options="translatedyesnoOptions" id="kitchen"
        class="mb-3" :state="$v.propertyData.kitchen.$dirty ? !$v.propertyData.kitchen.$error : null">
        <template v-slot:first>
                                                        <option :value="null" disabled>Select</option>
                                                    </template>
    </b-form-select>
    <b-form-invalid-feedback v-if="!propertyData.kitchen">
        This is a required field
    </b-form-invalid-feedback>
</b-form-group>
<b-form-group :label="$t('property_detail.tv')" label-for="tv">
    <b-form-select name="tv" v-model="propertyData.tv" :options="translatedyesnoOptions" id="tv" class="mb-3"
        :state="$v.propertyData.tv.$dirty ? !$v.propertyData.tv.$error : null">
        <template v-slot:first>
                                                        <option :value="null" disabled>Select</option>
                                                    </template>
    </b-form-select>
    <b-form-invalid-feedback v-if="!propertyData.tv">
        This is a required field
    </b-form-invalid-feedback>
</b-form-group>
<b-form-group :label="$t('property_detail.Number_of_guests')" label-for="max_guests">
    <b-form-input type="number" id="max_guests" name="max_guests" :placeholder="$t('property_detail.Number_of_guests')"
        v-model="$v.propertyData.max_guests.$model"
        :state="$v.propertyData.max_guests.$dirty ? !$v.propertyData.max_guests.$error : null"
        aria-describedby="input-1-live-feedback"></b-form-input>
    <b-form-invalid-feedback v-if="$v.propertyData.max_guests.$error" id="input-1-live-feedback">This is a required
        field
    </b-form-invalid-feedback>
</b-form-group>
</div>
<div class="col-lg-4 col-md-4">
    <b-form-group :label="$t('property_detail.Number_of_bedrooms')" label-for="num_bedrooms">
        <b-form-input type="number" id="num_bedrooms" name="num_bedrooms"
            :placeholder="$t('property_detail.Number_of_bedrooms')" v-model="$v.propertyData.num_bedrooms.$model"
            :state="$v.propertyData.num_bedrooms.$dirty ? !$v.propertyData.num_bedrooms.$error : null"
            aria-describedby="input-1-live-feedback"></b-form-input>
        <b-form-invalid-feedback v-if="$v.propertyData.num_bedrooms.$error" id="input-1-live-feedback">This is a
            required field
        </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group :label="$t('property_detail.Bedroom_Amenities')" class="d-flex align-items-center checkbox-group"
        label-class="custom-label">
        <b-form-checkbox v-model="propertyData.bedroom_Amenities" name="King_Bed" value="King_Bed" inline>
            {{ $t('property_detail.King_Bed') }}
        </b-form-checkbox>
        <b-form-checkbox v-model="propertyData.bedroom_Amenities" name="Double_Sofa_Bed" value="Double_Sofa_Bed" inline>
            {{ $t('property_detail.Double_Sofa_Bed') }}
        </b-form-checkbox>
        <b-form-checkbox v-model="propertyData.bedroom_Amenities" name="Double_Futon" value="Double_Futon" inline>
            {{ $t('property_detail.Double_Futon') }}
        </b-form-checkbox>
    </b-form-group>


    <b-form-group :label="$t('property_detail.Number_of_bathrooms')" label-for="num_bathrooms">
        <b-form-input type="number" id="num_bathrooms" name="num_bathrooms"
            :placeholder="$t('property_detail.Number_of_bathrooms')" v-model="$v.propertyData.num_bathrooms.$model"
            :state="$v.propertyData.num_bathrooms.$dirty ? !$v.propertyData.num_bathrooms.$error : null"
            aria-describedby="input-1-live-feedback"></b-form-input>
        <b-form-invalid-feedback v-if="$v.propertyData.num_bathrooms.$error" id="input-1-live-feedback">This is a
            required field
        </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Bathroom Amenities:" class="d-flex align-items-center checkbox-group"
        label-class="custom-label">
        <b-form-checkbox v-model="propertyData.bathroom_Amenities" name="Soap" value="Soap" inline>
            {{ $t('property_detail.Soap') }}
        </b-form-checkbox>
        <b-form-checkbox v-model="propertyData.bathroom_Amenities" name="Towels_provided" value="Towels_provided"
            inline>
            {{ $t('property_detail.Towels_provided') }}
        </b-form-checkbox>
        <b-form-checkbox v-model="propertyData.bathroom_Amenities" name="Toilet" value="Toilet" inline>
            {{ $t('property_detail.Toilet') }}
        </b-form-checkbox>
        <b-form-checkbox v-model="propertyData.bathroom_Amenities" name="Shower_only" value="Shower_only" inline>
            {{ $t('property_detail.Shower_only') }}
        </b-form-checkbox>
        <b-form-checkbox v-model="propertyData.bathroom_Amenities" name="Shampoo" value="Shampoo" inline>
            {{ $t('property_detail.Shampoo') }}
        </b-form-checkbox>
    </b-form-group>

</div>

<div class="col-lg-4 col-md-4">
    <b-form-group :label="$t('property_detail.price')" label-for="price">
        <div class="d-flex">
            <b-form-select v-model="propertyData.currency" :options="currencyOptions" required>
            </b-form-select>
            <b-form-input type="text" id="price" name="price" :placeholder="$t('property_detail.Price')"
                v-model="propertyData.price"
                :state="$v.propertyData.price.$dirty ? !$v.propertyData.price.$error : null"
                aria-describedby="input-1-live-feedback" required></b-form-input>

        </div>
        <b-form-invalid-feedback v-if="$v.propertyData.price.$error" id="input-1-live-feedback">
            This is a required field
        </b-form-invalid-feedback>
        <span v-if="propertyData.priceAmount" class="text-danger">{{
            propertyData.priceAmount }}</span>
    </b-form-group>
    <b-form-group :label="$t('properties_list.city')" label-for="city">
        <b-form-input type="text" name="city" id="city" :placeholder="$t('properties_list.city')"
            v-model="propertyData.city" :state="$v.propertyData.city.$dirty ? !$v.propertyData.city.$error : null"
            aria-describedby="input-1-live-feedback"></b-form-input>
        <b-form-invalid-feedback v-if="$v.propertyData.city.$error" id="input-1-live-feedback">This is a required field
        </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group :label="$t('properties_list.state')" label-for="state">
        <b-form-input type="text" id="state" name="state" :placeholder="$t('properties_list.state')"
            v-model="propertyData.state" :state="$v.propertyData.state.$dirty ? !$v.propertyData.state.$error : null"
            aria-describedby="input-1-live-feedback"></b-form-input>
        <b-form-invalid-feedback v-if="$v.propertyData.state.$error" id="input-1-live-feedback">This
            is a required field
        </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group :label="$t('property_detail.Country')" label-for="country">
        <b-form-input type="text" id="country" name="country" :placeholder="$t('property_detail.Country')"
            v-model="propertyData.country"
            :state="$v.propertyData.country.$dirty ? !$v.propertyData.country.$error : null"
            aria-describedby="input-1-live-feedback"></b-form-input>
        <b-form-invalid-feedback v-if="$v.propertyData.country.$error" id="input-1-live-feedback">This is a required
            field
        </b-form-invalid-feedback>
    </b-form-group>
</div>
<div class="col-lg-12 col-md-12">
    <b-form-group :label="$t('property_detail.Upload_Images')" label-for="images">
        <input type="file" id="images" name="images[]" @change="previewImages" multiple accept="image/*">
        <div v-if="propertyData.imagePreviews.length > 0" class="images-listing">
            <div v-for="(imagePreview, index) in propertyData.imagePreviews" :key="index" class="image-box">
                <img v-if="imagePreview.path" :src="imagePreview.path" alt="Preview"
                    style="max-width: 100px; max-height: 100px; margin-right: 5px;">
                <img v-else :src="imagePreview" alt="Preview"
                    style="max-width: 100px; max-height: 100px; margin-right: 5px;">
                <button type="button" class="btn btn-sm btn-danger" @click="removeImage(index)">
                    <i class="fas fa-trash-alt"></i> {{
                    $t('property_detail.Remove') }}
                </button>
            </div>
        </div>
    </b-form-group>
</div>
<div class="col-lg-4 col-md-4">
    <h4>House Rules</h4>
    <b-form-group label="Check In Aftter" label-for="checkin_after">
        <b-form-input type="time" id="checkin_after" name="checkin_after" placeholder="Check In Aftter"
            v-model="$v.propertyData.checkin_after.$model"
            :state="$v.propertyData.checkin_after.$dirty ? !$v.propertyData.checkin_after.$error : null"
            aria-describedby="checkinafter"></b-form-input>
        <b-form-invalid-feedback v-if="$v.propertyData.checkin_after.$error" id="checkinafter">This is a
            required field
        </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Children" label-for="Children">
        <b-form-select name="children" v-model="propertyData.children" :options="childrens"
            :state="$v.propertyData.children.$dirty ? !$v.propertyData.children.$error : null" id="children"
            class="mb-3" value-field="propertyType" text-field="children">
        </b-form-select>

        <b-form-invalid-feedback id="input-1-live-feedback">This is a required
            field.
        </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Smoking" label-for="Smoking">
        <b-form-select name="Smoking" v-model="propertyData.Smoking" :options="Smoking"
            :state="$v.propertyData.Smoking.$dirty ? !$v.propertyData.Smoking.$error : null" id="Smoking" class="mb-3"
            value-field="Smoking" text-field="Smoking">
        </b-form-select>
        <b-form-invalid-feedback id="Smoking">This is a required
            field.
        </b-form-invalid-feedback>
    </b-form-group>
</div>
<div class="col-lg-4 col-md-4" style="margin-top: 16px;">
    <h4></h4>
    <b-form-group label="Check out before" label-for="checkout_before">
        <b-form-input type="time" id="checkout_before" name="checkout_before"
            :placeholder="$t('property_detail.Number_of_bedrooms')" v-model="$v.propertyData.checkout_before.$model"
            :state="$v.propertyData.checkout_before.$dirty ? !$v.propertyData.checkout_before.$error : null"
            aria-describedby="checkoutbefore"></b-form-input>
        <b-form-invalid-feedback v-if="$v.propertyData.checkout_before.$error" id="checkoutbefore">This is a
            required field
        </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Events" label-for="Events">
        <b-form-select name="Events" v-model="propertyData.Events" :options="Events"
            :state="$v.propertyData.Events.$dirty ? !$v.propertyData.Events.$error : null" id="Events" class="mb-3"
            value-field="propertyType" text-field="Events">
        </b-form-select>
        <b-form-invalid-feedback id="Events">This is a required
            field.
        </b-form-invalid-feedback>
    </b-form-group>
</div>
<div class="col-lg-4 col-md-4" style="margin-top: 16px;">
    <h4></h4>
    <b-form-group label="Minimum age to rent" label-for="min_age">
        <b-form-input type="number" id="min_age" name="min_age" :placeholder="$t('property_detail.Number_of_bedrooms')"
            v-model="$v.propertyData.min_age.$model"
            :state="$v.propertyData.min_age.$dirty ? !$v.propertyData.min_age.$error : null"
            aria-describedby="minage"></b-form-input>
        <b-form-invalid-feedback v-if="$v.propertyData.min_age.$error" id="minage">This is a
            required field
        </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Pets" label-for="Pets">
        <b-form-select name="Pets" v-model="propertyData.Pets" :options="Pets"
            :state="$v.propertyData.Pets.$dirty ? !$v.propertyData.Pets.$error : null" id="Pets" class="mb-3"
            value-field="propertyType" text-field="Pets">
        </b-form-select>
        <b-form-invalid-feedback id="Pets">This is a required
            field.
        </b-form-invalid-feedback>
    </b-form-group>
</div>
<div class="col-lg-12 col-md-12">
    <b-form-group :label="$t('property_detail.Description')" label-for="description">
        <b-form-textarea id="description" v-model="this.propertyData.description"
            :placeholder="$t('property_detail.Description')" rows="3" max-rows="3">
        </b-form-textarea>
    </b-form-group>
</div>
</div>
<b-button type="submit" variant="success" class="mr-2">
    <span v-if="propertyData.id === ''">{{ $t('ratings_and_reviews.submit')
        }}</span>
    <span v-else>{{ $t('property_detail.Update') }}</span>
</b-button>
</form>
</div>
</div>
</div>
</div>
</div>
</div> -->
        <div class="row">
            <div class="col-xl-12">
                <div class="left-card m-b-30">
                    <div class="Customer-card-body">
                        <div v-if="regMsgTextErr != ''">
                            <div class="alert-message md-form">
                                <div class="alert alert-danger">{{ regMsgTextErr }}</div>
                            </div>
                        </div>
                        <div v-if="regMsgTextSucc != ''">
                            <div class="alert-message md-form">
                                <div class="alert alert-success">{{ regMsgTextSucc }}</div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 1">
                            <div class="card-heading">
                                <h5>What is the Type of your accommodation?</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group m-0">
                                    <div class="radio-buttons-wrapper">
                                        <div v-for="(text, value) in translatedPropertyTypeOptions" :key="value"
                                            class="form-check ">
                                            <input type="radio" :id="'propertyType_' + value" :value="value"
                                                v-model="propertyData.propertyType" class="form-check-input"
                                                name="propertyType">
                                            <label style="margin-left: 0px;" :for="'propertyType_' + value"
                                                class="form-check-label ">{{ text
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card second-step" v-if="currStep == 2">
                            <div class="card-heading">
                                <h5>What services does your accommodation offer to guests?</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group m-0">
                                    <div class="radio-buttons-wrapper">
                                        <label class="form-label" for="wifiAvailable">{{ $t('property_detail.wifi')
                                            }}</label>
                                        <div v-for="(optionText, optionValue) in translatedyesnoOptions"
                                            :key="optionValue" class="form-check">
                                            <input type="radio" :id="'wifiAvailable_' + optionValue"
                                                :value="optionValue" v-model="propertyData.wifiAvailable"
                                                class="form-check-input">
                                            <label :for="'wifiAvailable_' + optionValue" class="form-check-label">{{
                                                optionText
                                                }}</label>
                                        </div>
                                    </div>
                                    <div class="radio-buttons-wrapper">
                                        <label class="form-label">{{ $t('property_detail.kitchen') }}</label>
                                        <div v-for="(optionText, optionValue) in translatedyesnoOptions"
                                            :key="optionValue" class="form-check">
                                            <input type="radio" :id="'kitchen_' + optionValue" :value="optionValue"
                                                v-model="propertyData.kitchen" class="form-check-input">
                                            <label :for="'kitchen_' + optionValue" class="form-check-label">{{
                                                optionText
                                            }}</label>
                                        </div>
                                    </div>
                                    <div class="radio-buttons-wrapper">
                                        <label class="form-label">{{ $t('property_detail.tv') }}</label>
                                        <div v-for="(optionText, optionValue) in translatedyesnoOptions"
                                            :key="optionValue" class="form-check">
                                            <input type="radio" :id="'tv_' + optionValue" :value="optionValue"
                                                v-model="propertyData.tv" class="form-check-input">
                                            <label :for="'tv_' + optionValue" class="form-check-label">{{ optionText
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="card-body md-form">
                                    <label for="regEmail">{{ $t('register.email') }} <span
                                            class="text-red">*</span></label>
                                    <input id="regEmail" name="regEmail" type="text" class="form-control"
                                        autocomplete="off" v-model="regEmail">
                                </div> -->
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 3">
                            <div class="card-heading">
                                <h5>Accommodation details</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group">
                                    <label for="max_guests">{{ $t('property_detail.Number_of_guests') }} <span
                                            class="text-red">*</span></label>
                                    <input type="number" id="max_guests" name="max_guests"
                                        :placeholder="$t('property_detail.Number_of_guests')" class="form-control"
                                        autocomplete="off" v-model="$v.propertyData.max_guests.$model" :maxlength="16"
                                        :state="$v.propertyData.max_guests.$dirty ? !$v.propertyData.max_guests.$error : null"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                                <div class="form-group">
                                    <label for="num_bedrooms">{{ $t('property_detail.Number_of_bedrooms') }} <span
                                            class="text-red">*</span></label>
                                    <input type="number" id="num_bedrooms" name="num_bedrooms"
                                        :placeholder="$t('property_detail.Number_of_bedrooms')" class="form-control"
                                        autocomplete="off" v-model="$v.propertyData.num_bedrooms.$model"
                                        :state="$v.propertyData.num_bedrooms.$dirty ? !$v.propertyData.num_bedrooms.$error : null"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                                <div class="form-group">
                                    <label for="num_bathrooms">{{ $t('property_detail.Number_of_bathrooms') }} <span
                                            class="text-red">*</span></label>
                                    <input type="number" id="num_bathrooms" name="num_bathrooms"
                                        :placeholder="$t('property_detail.Number_of_bathrooms')" class="form-control"
                                        autocomplete="off" v-model="$v.propertyData.num_bathrooms.$model"
                                        :state="$v.propertyData.num_bathrooms.$dirty ? !$v.propertyData.num_bathrooms.$error : null"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                                <!-- <div class="card-body md-form">
                                    <label for="regPhone">{{ $t('register.phone') }} <span class="text-red">*</span></label>
                                    <input id="regPhone" name="regPhone" maxlength="16" @input="enforcePhoneFormat()"
                                        type="number" placeholder="(123) 456-7890" class="form-control" autocomplete="off"
                                        v-model="regPhone">
                                </div> -->
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 4">
                            <div class="card-heading">
                                <h5>Amenities details</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group mb-4">
                                    <div class="checkbox-group">
                                        <label class="custom-label">{{ $t('property_detail.Bedroom_Amenities')
                                            }}</label>

                                        <div class="form-check">
                                            <input type="checkbox" id="King_Bed" name="King_Bed" value="King_Bed"
                                                v-model="propertyData.bedroom_Amenities" class="form-check-input">
                                            <label for="King_Bed" class="form-check-label">{{
                                                $t('property_detail.King_Bed')
                                                }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Double_Sofa_Bed" name="Double_Sofa_Bed"
                                                value="Double_Sofa_Bed" v-model="propertyData.bedroom_Amenities"
                                                class="form-check-input">
                                            <label for="Double_Sofa_Bed" class="form-check-label">{{
                                                $t('property_detail.Double_Sofa_Bed') }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Double_Futon" name="Double_Futon"
                                                value="Double_Futon" v-model="propertyData.bedroom_Amenities"
                                                class="form-check-input">
                                            <label for="Double_Futon" class="form-check-label">{{
                                                $t('property_detail.Double_Futon')
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group m-0">
                                    <div class="checkbox-group">
                                        <label class="custom-label">Bathroom Amenities:</label>

                                        <div class="form-check">
                                            <input type="checkbox" id="Soap" name="Soap" value="Soap"
                                                v-model="propertyData.bathroom_Amenities" class="form-check-input">
                                            <label for="Soap" class="form-check-label">{{ $t('property_detail.Soap')
                                                }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Towels_provided" name="Towels_provided"
                                                value="Towels_provided" v-model="propertyData.bathroom_Amenities"
                                                class="form-check-input">
                                            <label for="Towels_provided" class="form-check-label">{{
                                                $t('property_detail.Towels_provided') }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Toilet" name="Toilet" value="Toilet"
                                                v-model="propertyData.bathroom_Amenities" class="form-check-input">
                                            <label for="Toilet" class="form-check-label">{{ $t('property_detail.Toilet')
                                                }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Shower_only" name="Shower_only"
                                                value="Shower_only" v-model="propertyData.bathroom_Amenities"
                                                class="form-check-input">
                                            <label for="Shower_only" class="form-check-label">{{
                                                $t('property_detail.Shower_only')
                                                }}</label>
                                        </div>

                                        <div class="form-check">
                                            <input type="checkbox" id="Shampoo" name="Shampoo" value="Shampoo"
                                                v-model="propertyData.bathroom_Amenities" class="form-check-input">
                                            <label for="Shampoo" class="form-check-label">{{
                                                $t('property_detail.Shampoo')
                                                }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 5">
                            <div class="card-heading">
                                <h5>Upload Images:</h5>
                            </div>
                            <div class="card-body md-form step-five">
                                <div class="form-group">
                                    <label for="images">{{ $t('property_detail.Upload_Images') }}</label>
                                    <input type="file" id="images" name="images[]" @change="previewImages" multiple
                                        accept="image/*">

                                    <div v-if="propertyData.imagePreviews.length > 0" class="images-listing">
                                        <div v-for="(imagePreview, index) in propertyData.imagePreviews" :key="index"
                                            class="images-card">
                                            <img v-if="imagePreview.path" :src="imagePreview.path" alt="Preview">
                                            <img v-else :src="imagePreview" alt="Preview">
                                            <button type="button" class="btn btn-primary" @click="removeImage(index)">
                                                <i class="fas fa-trash-alt"></i> {{ $t('property_detail.Remove') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 6">
                            <div class="card-heading">
                                <h5>Description</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group">
                                    <label for="description">{{ $t('property_detail.Description') }}</label>
                                    <textarea id="description" class="form-control" v-model="propertyData.description"
                                        :placeholder="$t('property_detail.Description')" rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 7">
                            <div class="card-heading">
                                <h5>Price per night</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group">
                                    <label for="price">{{ $t('property_detail.price') }}</label>
                                    <div>
                                        <!-- <select class="form-control" v-model="propertyData.currency" required>
                                            <option v-for="option in currencyOptions" :key="option.value"
                                                :value="option.value">
                                                {{ option.text }}</option>
                                        </select> -->
                                        <input type="text" id="price" name="price" class="form-control mt-4"
                                            :placeholder="$t('property_detail.Price')" v-model="propertyData.price"
                                            :state="$v.propertyData.price.$dirty ? !$v.propertyData.price.$error : null"
                                            aria-describedby="input-1-live-feedback" required>
                                    </div>
                                    <div class="invalid-feedback" v-if="$v.propertyData.price.$error"
                                        id="input-1-live-feedback">
                                        This is a required field
                                    </div>
                                    <span v-if="propertyData.priceAmount" class="text-danger">{{
                                        propertyData.priceAmount
                                        }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 8">
                            <div class="card-heading">
                                <h5>House Rules</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group">
                                    <label for="checkin_after" class="form-label">Check In After</label>
                                    <input type="time" id="checkin_after" name="checkin_after"
                                        placeholder="Check In After" v-model="$v.propertyData.checkin_after.$model"
                                        :class="{ 'is-invalid': $v.propertyData.checkin_after.$dirty && $v.propertyData.checkin_after.$error }"
                                        aria-describedby="checkinafter" class="form-control">
                                    <div v-if="$v.propertyData.checkin_after.$error" id="checkinafter"
                                        class="invalid-feedback">
                                        This is a required field
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="checkout_before" class="form-label">Check out before</label>
                                    <input type="time" id="checkout_before" name="checkout_before"
                                        :placeholder="$t('property_detail.Check_out_before')"
                                        v-model="$v.propertyData.checkout_before.$model"
                                        :class="{ 'is-invalid': $v.propertyData.checkout_before.$dirty && $v.propertyData.checkout_before.$error }"
                                        aria-describedby="checkoutbefore" class="form-control">
                                    <div v-if="$v.propertyData.checkout_before.$error" id="checkoutbefore"
                                        class="invalid-feedback">
                                        This is a required field
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="min_age" class="form-label">Minimum age to rent</label>
                                    <input type="number" id="min_age" name="min_age" placeholder="Minimum age to rent"
                                        v-model="$v.propertyData.min_age.$model"
                                        :class="{ 'is-invalid': $v.propertyData.min_age.$dirty && $v.propertyData.min_age.$error }"
                                        aria-describedby="minage" class="form-control">
                                    <div v-if="$v.propertyData.min_age.$error" id="minage" class="invalid-feedback">
                                        This is a required field
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="children" class="form-label">Children</label>
                                    <select id="children" name="children" v-model="propertyData.children"
                                        :class="{ 'is-invalid': $v.propertyData.children.$dirty && $v.propertyData.children.$error }"
                                        class="form-control mb-3">
                                        <option value="" disabled>Select an option</option>
                                        <option v-for="option in childrens" :key="option" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                    <div v-if="$v.propertyData.children.$error" id="input-1-live-feedback"
                                        class="invalid-feedback">
                                        This is a required field.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Events" class="form-label">Events</label>
                                    <select id="Events" name="Events" v-model="propertyData.Events"
                                        :class="{ 'is-invalid': $v.propertyData.Events.$dirty && $v.propertyData.Events.$error }"
                                        class="form-control mb-3">
                                        <option value="" disabled>Select an option</option>
                                        <option v-for="option in Events" :key="option" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                    <div v-if="$v.propertyData.Events.$error" id="Events-feedback"
                                        class="invalid-feedback">
                                        This is a required field.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Pets" class="form-label">Pets</label>
                                    <select id="Pets" name="Pets" v-model="propertyData.Pets"
                                        :class="{ 'is-invalid': $v.propertyData.Pets.$dirty && $v.propertyData.Pets.$error }"
                                        class="form-control mb-3">
                                        <option value="" disabled>Select an option</option>
                                        <option v-for="option in Pets" :key="option" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                    <div v-if="$v.propertyData.Pets.$error" id="Pets-feedback" class="invalid-feedback">
                                        This is a required field.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Smoking" class="form-label">Smoking</label>
                                    <select id="Smoking" name="Smoking" v-model="propertyData.Smoking"
                                        :class="{ 'is-invalid': $v.propertyData.Smoking.$dirty && $v.propertyData.Smoking.$error }"
                                        class="form-control mb-3">
                                        <option value="" disabled>Select an option</option>
                                        <option v-for="option in Smoking" :key="option" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                    <div v-if="$v.propertyData.Smoking.$error" id="Smoking-feedback"
                                        class="invalid-feedback">
                                        This is a required field.
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="card" v-if="currStep == 9">
                            <div class="card-heading">
                                <h5>Where is the accommodation you want to add located?</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group">
                                    <label for="city">{{ $t('properties_list.city') }}</label>
                                    <input type="text" id="city" name="city" class="form-control"
                                        :placeholder="$t('properties_list.city')" v-model="propertyData.city"
                                        :class="{ 'is-invalid': $v.propertyData.city.$error }"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                                <div class="form-group">
                                    <label for="state">{{ $t('properties_list.state') }}</label>
                                    <input type="text" id="state" name="state" class="form-control"
                                        :placeholder="$t('properties_list.state')" v-model="propertyData.state"
                                        :class="{ 'is-invalid': $v.propertyData.state.$error }"
                                        aria-describedby="input-1-live-feedback">
                                </div>
                                <div class="form-group">
                                    <label for="country">{{ $t('property_detail.Country') }}</label>
                                    <input type="text" id="country" name="country" class="form-control"
                                        :placeholder="$t('property_detail.Country')" v-model="propertyData.country"
                                        :class="{ 'is-invalid': $v.propertyData.country.$error }"
                                        aria-describedby="input-1-live-feedback">
                                </div>


                            </div>
                        </div>
                        <div v-if="currStep == 10">
                            <div class="card-heading">
                                <h5>Account Details</h5>
                            </div>
                            <div class="card-body md-form">
                                <div class="form-group">
                                    <label for="checkin_after" class="form-label">Cardholder's Name</label>
                                    <input type="text" id="Cardholder's Name" name="Cardholder's Name"
                                        placeholder="Cardholder's Name" aria-describedby="checkinafter"
                                        class="form-control">
                                    <div v-if="$v.propertyData.checkin_after.$error" id="checkinafter"
                                        class="invalid-feedback">
                                        This is a required field
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="checkin_after" class="form-label">Card Number</label>
                                    <input type="text" id="Card Number" name="Card Number" placeholder="Card Number"
                                        aria-describedby="checkinafter" class="form-control">
                                    <div v-if="$v.propertyData.checkin_after.$error" id="checkinafter"
                                        class="invalid-feedback">
                                        This is a required field
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="checkin_after" class="form-label">CVV</label>
                                            <input type="text" id="CVV" name="CVV" placeholder="CVV"
                                                aria-describedby="checkinafter" class="form-control">
                                            <div v-if="$v.propertyData.checkin_after.$error" id="checkinafter"
                                                class="invalid-feedback">
                                                This is a required field
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="checkin_after" class="form-label">Exp Date</label>
                                            <input type="date" id="Exp Date" name="Exp Date" placeholder="Exp Date"
                                                aria-describedby="checkinafter" class="form-control">
                                            <div v-if="$v.propertyData.checkin_after.$error" id="checkinafter"
                                                class="invalid-feedback">
                                                This is a required field
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card laststep" v-if="currStep == 11">
                            <div class="card-heading">
                                <h5>All Done!</h5>
                            </div>
                            <div class="md-form">
                                <button type="button" class="btn btn-primary" @click="addProperty()"
                                    style="margin-left: 60%; ">List Your Property</button>
                            </div>
                        </div>
                        <div class="card" v-if="currStep < 12">
                            <div class="footer-buttons md-form">
                                <button id="bckBtn" name="bckBtn" class="btn btn-default pull-left" v-if="currStep > 1"
                                    @click="bckBtn" :disabled="regBusy">
                                    <i class="fa fa-angle-left"></i> <span>{{ $t('register.back') }}</span>
                                </button>
                                <!-- <button id="bckBtn" name="bckBtn" class="btn btn-default pull-left" v-else
                                    @click="redirect('/')">
                                    <i class="fa fa-angle-left"></i>
                                     <span>{{ $t('register.home') }}</span>
                                </button> -->
                                <button id="regSub" name="regSub" class="btn btn-primary pull-right" @click="regSub"
                                    :disabled="regBusy" v-if="currStep !== 11">{{ regBtnText }}</button>
                            </div>
                        </div>
                        <div class="card" v-if="currStep == 12">
                            <div class="card-body md-form">
                                <button id="regSingIn" name="regSingIn" class="btn btn-primary pull-right"
                                    @click="regSingIn">{{
                                        $t('register.sign_in') }}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
import Vue from "vue";
import API from '@/api'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import { validationMixin } from "vuelidate";
import { required, minLength, url, email, sameAs } from "vuelidate/lib/validators";

const options = {
    toast: {
        position: SnotifyPosition.rightTop,
    }
}
Vue.use(Snotify, options)
export default {
    mixins: [validationMixin],
    data() {
        return {
            currStep: 1,
            regBtnText: this.$t('register.next'),
            regBusy: false,
            regEmail: '',
            regPhone: '',
            regFname: '',
            type: 'password',
            regPassword: '',
            btnText: 'fa fa-eye',
            regCpassword: '',
            regMsgTextErr: '',
            regMsgTextSucc: '',





            bathroom_Amenities: {
                Soap: false,
                Towels_provided: false,
                Toilet: false,
                Shower_only: false,
                Shampoo: false,
            },
            bedroom_Amenities: {
                King_Bed: false,
                Double_Sofa_Bed: false,
                Double_Futon: false,
            },
            propertyData: {
                lang: "en",
                city: "",
                state: "",
                country: "",
                price: "",
                priceAmount: "",
                max_guests: "",
                num_bedrooms: "",
                checkin_after: "",
                checkout_before: "",
                num_bathrooms: "",
                min_age: "",
                propertyType: "",
                children: "",
                Events: "",
                Pets: "",
                Smoking: "",
                wifiAvailable: "",
                kitchen: "",
                tv: "",
                description: "",
                imagePreviews: [],
                bathroom_Amenities: [],
                bedroom_Amenities: [],
                id: "",
                currency: 'USD',
            },
            currencyOptions: [
                { value: 'USD', text: 'USD' },
                { value: 'EUR', text: 'Euro' },
                { value: 'GBP', text: 'Pound' },
                // Add more currencies as needed
            ],
            id: "",
            propertyTypeOptions: {
                'Entire Place': 'Entire Place',
                'Private Room': 'Private Room',
                'Shared Room': 'Shared Room',
                'Beachfront': 'Beachfront',
                'Mountain': 'Mountain',
                'Camping': 'Camping',
                'Lakefront': 'Lakefront',
                'Ski': 'Ski',
                'Desert': 'Desert',
                'Tropical': 'Tropical',
                'With_pool': 'With pool',
                'With_views': 'With views',
            },
            childrens: {
                'Not Allowd': 'Not Allowd',
                'Allowed: ages 0-13': 'Allowed: ages 0-13',
                'Allowed: ages 0-17': 'Allowed: ages 0-17',
            },
            Events: {
                'Not Allowd': 'Not Allowd',
                'Allowed: Family gatherings and Birthday parties': 'Allowed: Family gatherings and Birthday parties',
                'Allowed: Family gatherings': 'Allowed: Family gatherings',
                'Allowed: Birthday parties': 'Allowed:  Birthday parties',
            },
            Pets: {
                'Not Allowd': 'Not Allowd',
                'Allowed: cats less than 20 lbs per pet (limit 1 pets total)': 'Allowed: cats less than 20 lbs per pet (limit 1 pets total)',
                'Allowed: cats less than 20 lbs per pet (limit 2 pets total)': 'Allowed: cats less than 20 lbs per pet (limit 2 pets total)',
                'Allowed: cats less than 20 lbs per pet (limit 3 pets total)': 'Allowed: cats less than 20 lbs per pet (limit 3 pets total)',
            },
            Smoking: {
                'Not Allowd': 'Not Allowd',
                'Allowed': 'Allowed',
                'Allowed: in designated areas': 'Allowed: in designated areas',
            },
            yesNoOptions: {
                1: 'Yes',
                0: 'No'
            },
        };
    },
    validations: {
        propertyData: {

            num_bathrooms: {
                required,
            },
            max_guests: {
                required,
            },
            num_bedrooms: {
                required,
            },
            checkin_after: {
                required,

            },
            checkout_before: {
                required,
            },
            min_age: {
                required,
            },
            Events: {
                required,
            },
            Pets: {
                required,
            },
            Smoking: {
                required,
            },
            price: {
                required,
            },
            country: {
                required,
            },
            state: {
                required,
            },
            city: {
                required,
            },
            propertyType: {
                required,
            },
            children: {
                required,
            },
            wifiAvailable: {
                required,
            }, kitchen: {
                required,
            }, tv: {
                required,
            },
        }
    },

    methods: {
        showPassword() {
            if (this.type === 'password') {
                this.type = 'text'
                this.btnText = 'fa fa-eye-slash'
            } else {
                this.type = 'password'
                this.btnText = 'fa fa-eye'
            }
        },
        bckBtn() {
            if (this.currStep > 1) {
                this.regMsgTextErr = '';
                this.regBtnText = this.$t('register.next');
                this.currStep = this.currStep - 1;
            }
        },
        enforcePhoneFormat() {
            let x = this.regPhone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

            this.regPhone = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        },
        regSub() {
            this.regMsgTextErr = '';
            if (this.currStep === 1) {
                this.$v.propertyData.propertyType.$touch();

                if (!this.$v.propertyData.propertyType.required) {
                    this.regMsgTextErr = 'Please Select Type Of your Property';
                    return;
                }
                this.currStep = 2;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep === 2) {
                this.regBtnText = this.$t('register.next');

                if (!this.propertyData.wifiAvailable) {
                    this.regMsgTextErr = 'Please Select Any Option in Wifi';
                    return;
                }
                if (!this.propertyData.kitchen) {
                    this.regMsgTextErr = 'Please Select Any Option in Kitchen';
                    return;
                }
                if (!this.propertyData.tv) {
                    this.regMsgTextErr = 'Please Select Any Option in TV';
                    return;
                }
                this.currStep = 3;
                this.regBtnText = this.$t('register.next');

            } else if (this.currStep == 3) {
                this.regBtnText = this.$t('register.next');
                if (!this.propertyData.max_guests) {
                    this.regMsgTextErr = 'Please Add the Number Of Gusets';
                    return;
                }
                if (!this.propertyData.num_bedrooms) {
                    this.regMsgTextErr = 'Please Add the Number Of Bedrooms';
                    return;
                }
                if (!this.propertyData.num_bathrooms) {
                    this.regMsgTextErr = 'Please Add the Number Of Bathrooms';
                    return;
                }
                this.currStep = 4;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 4) {
                this.currStep = 5;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 5) {
                if (!this.propertyData.imagePreviews.length) {
                    this.regMsgTextErr = 'Please Add the Images of Property';
                    return;
                }
                if (this.propertyData.imagePreviews.length < 4) {
                    this.regMsgTextErr = 'Please Add Atleasr 4 Images of Property';
                    return;
                }
                this.currStep = 6;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 6) {

                this.currStep = 7;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 7) {
                if (!this.propertyData.price) {
                    this.regMsgTextErr = 'Please Add the Price Per night';
                    return;
                }
                if (this.propertyData.price > 89) {
                    this.propertyData.priceAmount = "Price must be $89 or less";
                    return;
                }
                this.currStep = 8;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 8) {
                if (!this.propertyData.checkin_after) {
                    this.regMsgTextErr = 'Please Add the Checkin TIme';
                    return;
                }
                if (!this.propertyData.checkout_before) {
                    this.regMsgTextErr = 'Please Add the Checkout TIme';
                    return;
                } if (!this.propertyData.min_age) {
                    this.regMsgTextErr = 'Please Add Min Age to rent';
                    return;
                } if (!this.propertyData.children) {
                    this.regMsgTextErr = 'Please Add Add information about childers';
                    return;
                }
                if (!this.propertyData.Events) {
                    this.regMsgTextErr = 'Please Add Add information about Events';
                    return;
                }
                if (!this.propertyData.Pets) {
                    this.regMsgTextErr = 'Please Add Add information about Pets';
                    return;
                }
                if (!this.propertyData.Smoking) {
                    this.regMsgTextErr = 'Please Add Add information about Smoking';
                    return;
                }
                if (this.propertyData.price > 89) {
                    this.propertyData.priceAmount = "Price must be $89 or less";
                    return;
                }
                this.currStep = 9;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 9) {
                if (!this.propertyData.city) {
                    this.regMsgTextErr = 'Please Add the City';
                    return;
                }
                if (!this.propertyData.state) {
                    this.regMsgTextErr = 'Please Add the state';
                    return;
                }
                if (!this.propertyData.country) {
                    this.regMsgTextErr = 'Please Add the Country';
                    return;
                }
                this.currStep = 10;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 10) { 
                this.currStep = 11;
                this.regBtnText = this.$t('register.next');
            } else if (this.currStep == 11) {
                this.regBtnText = this.$t('register.next');
            }
        },

        previewImages(event) {
            const files = event.target.files;
            if (files) {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    if (file) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            this.propertyData.imagePreviews.push(e.target.result);
                            this.$forceUpdate();
                        };
                        reader.readAsDataURL(file);
                    }
                }
            }
        },
        removeImage(index) {
            this.propertyData.imagePreviews.splice(index, 1);
            this.$forceUpdate();
        },
        addProperty() {
            this.$v.propertyData.$touch();
            if (this.propertyData.price > 89) {
                this.propertyData.priceAmount = "Price must be $89 or less";
                return;
            }
            this.propertyData.priceAmount = "";
            if (this.$v.propertyData.$anyError) {
                return;
            }
            if (this.propertyData.id) {
                API.updateProperty(this.propertyData, data => {
                    if (data.status == 200) {
                        this.$router.push({ name: 'properties' });
                    } else {
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: data.message,
                        })
                    }
                    this.isDisabled = false;
                }, this.errorCB);
            } else {
                API.addProperty(this.propertyData, data => {
                    if (data.status == 200) {
                        this.$router.push({ name: 'properties' });
                    } else {
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: data.message,
                        })
                    }
                    this.isDisabled = false;
                }, this.errorCB);
            }

        },
        editProperty() {
            if (this.$route.params.data) {
                this.propertyData = this.$route.params.data;
                this.propertyData.imagePreviews = this.$route.params.data.photos;
                this.propertyData.propertyType = this.$route.params.data.property_type;
                this.propertyData.wifiAvailable = this.$route.params.data.wifi_available;
                this.propertyData.price = this.$route.params.data.price;
                this.propertyData.kitchen = this.$route.params.data.kitchen_available;
                this.propertyData.tv = this.$route.params.data.tv_available;
                this.propertyData.lang = this.$i18n.locale;
                this.propertyData.description = this.$route.params.data.property_description;
                if (typeof this.propertyData.bathroom_Amenities === 'string') {
                    this.propertyData.bathroom_Amenities = JSON.parse(this.propertyData.bathroom_Amenities);
                }
                if (Array.isArray(this.propertyData.bathroom_Amenities)) {
                    this.propertyData.bathroom_Amenities.forEach(amenity => {
                        if (amenity === "Soap") {
                            this.propertyData.soapAvailable = true;
                        } else if (amenity === "Shower_only") {
                            this.propertyData.showerOnly = true;
                        } else if (amenity === "Towels_provided") {
                            this.propertyData.towelsProvided = true;
                        } else if (amenity === "Shampoo") {
                            this.propertyData.shampooAvailable = true;
                        } else if (amenity === "Toilet") {
                            this.propertyData.toiletAvailable = true;
                        }
                    });
                }
                if (typeof this.propertyData.bedroom_Amenities === 'string') {
                    this.propertyData.bedroom_Amenities = JSON.parse(this.propertyData.bedroom_Amenities);
                }
                if (Array.isArray(this.propertyData.bedroom_Amenities)) {
                    this.propertyData.bedroom_Amenities.forEach(amenity => {
                        if (amenity === "King_Bed") {
                            this.propertyData.bedroom_Amenities.King_Bed = true;
                        } else if (amenity === "Double_Sofa_Bed") {
                            this.propertyData.bedroom_Amenities.Double_Sofa_Bed = true;
                        } else if (amenity === "Double_Futon") {
                            this.propertyData.bedroom_Amenities.Double_Futon = true;
                        }
                    });
                }
            }
        }

    }, computed: {

        // translatedstate: {
        //     get() {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             return this.propertyData.state;
        //         } else {
        //             return this.propertyData[`state_${lang}`];
        //         }
        //     },
        //     set(newValue) {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             this.propertyData.state = newValue;
        //         } else {
        //             this.propertyData[`state_${lang}`] = newValue;
        //         }
        //     }
        // },
        // translatedCity: {
        //     get() {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             return this.propertyData.city;
        //         } else {
        //             return this.propertyData[`city_${lang}`];
        //         }
        //     },
        //     set(newValue) {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             this.propertyData.city = newValue;
        //         } else {
        //             this.propertyData[`city_${lang}`] = newValue;
        //         }
        //     }
        // },
        // translatedcountry: {
        //     get() {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             return this.propertyData.country;
        //         } else {
        //             return this.propertyData[`country_${lang}`];
        //         }
        //     },
        //     set(newValue) {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             this.propertyData.country = newValue;
        //         } else {
        //             this.propertyData[`country_${lang}`] = newValue;
        //         }
        //     }
        // },
        // translatedDescription: {
        //     get() {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             return this.propertyData.property_description;
        //         } else {
        //             return this.propertyData[`property_description_${lang}`];
        //         }
        //     },
        //     set(newValue) {
        //         const lang = this.$i18n.locale;
        //         if (lang === 'en') {
        //             this.propertyData.property_description = newValue;
        //         } else {
        //             this.propertyData[`property_description_${lang}`] = newValue;
        //         }
        //     }
        // },
        translatedPropertyTypeOptions() {
            const translatedOptions = {};
            for (const key in this.propertyTypeOptions) {
                translatedOptions[key] = this.$t(`property_detail.${key}`);
            }
            return translatedOptions;
        },
        translatedyesnoOptions() {
            const translatedOptions = {};
            for (const key in this.yesNoOptions) {
                translatedOptions[key] = this.$t(`property_detail.${key}`);
            }
            return translatedOptions;
        }
    },
    mounted() {
        this.editProperty();
    },
    watch: {
        // translatedcountrys(newValue) {
        //     this.propertyData.country = newValue;
        // },
        // translatedState(newValue) {
        //     this.propertyData.state = newValue;
        // },
        // translatedCity(newValue) {
        //     this.propertyData.city = newValue;
        // },
        // translatedDescription(newValue) {
        //     this.propertyData.property_description = newValue;
        // },
        '$i18n.locale': function (newLocale, oldLocale) {
            this.propertyData.lang = this.$i18n.locale;

        }
    }
}
</script>

<style></style>

<style>
.checkbox-group .custom-control-label {
    padding-top: 5px !important;
}

.custom-label {
    font-size: 0.875rem;
}

.checkbox-group .b-form-checkbox {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    vertical-align: middle;
}

.Customer-card-body {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.Customer-card-body .card {
    border: 0;
}

.Customer-card-body .card .card-heading h5 {
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.2;
}

.Customer-card-body .card-body {
    border: 1px solid #bcbcbf;
    border-radius: 10px;
    padding: 20px;
}

.Customer-card-body .card-body .form-group .form-check {
    margin-top: 0;
    margin-bottom: 15px;
    width: 50%;
}

.Customer-card-body .card-body .form-group .radio-buttons-wrapper {
    padding-left: 1.25rem;
    display: flex;
    flex-wrap: wrap;
}

.Customer-card-body .card .card-heading {
    margin-bottom: 15px;
}

.footer-buttons.md-form {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-buttons.md-form .btn.btn-default {
    border: 1px solid #70d3ff;
    background: #fff;
    width: 105px;
    margin-right: 15px;
    font-weight: 500;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;
}

.footer-buttons.md-form .btn.btn-default:hover {
    background: #70d3ff;
    color: #fff;
}

.footer-buttons.md-form .btn.btn-primary {
    width: 100%;
    border-radius: 5px !important;
}

.Customer-card-body .card.second-step .card-body .form-group {
    margin: 0;
}

.Customer-card-body .card.second-step .card-body .form-group .radio-buttons-wrapper {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.Customer-card-body .card.second-step .card-body .form-group .radio-buttons-wrapper label.form-check-label {
    margin-left: 0;
}

.Customer-card-body .card.second-step .card-body .form-group .radio-buttons-wrapper label.form-label {
    width: 100%;
    font-weight: 500;
}

.Customer-card-body .card.second-step .card-body .form-group .form-check {
    width: 70px;
    padding-left: 20px;
}

.Customer-card-body .card-body .form-group .form-control {
    border-radius: 35px;
}

.Customer-card-body .card-body .form-group .form-control {
    border-radius: 35px;
    border: 1px solid #676A7D;
}

.Customer-card-body .card-body .form-group .checkbox-group .form-check .form-check-label {
    margin: 0;
}

.Customer-card-body .card-body .form-group .checkbox-group .form-check {
    padding-left: 20px;
}

.Customer-card-body .card-body .form-group .checkbox-group .custom-label {
    font-weight: 500;
}

.Customer-card-body .card-body.step-five .form-group label {
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
}

.Customer-card-body .card-body.step-five .form-group {
    margin: 0;
}

.images-listing {
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
}

.images-listing .images-card {
    margin-top: 15px;
    width: 22.8%;
}

.images-listing .images-card img {
    width: 100%;
}

.images-listing .images-card button.btn {
    width: 100% !important;
    margin-top: 5px !important;
    border-radius: 5px !important;
    height: 30px;
    padding: 0 !important;
    font-size: 14px !important;
}

.Customer-card-body .card-body.step-five .form-group input[type=file] {
    font-size: 14px;
}

.Customer-card-body .card-body.step-five .form-group input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #70d3ff;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
}

.Customer-card-body .card-body .form-group textarea.form-control {
    padding: 10px;
    height: 104px;
    border-radius: 15px;
}

.Customer-card-body .card.laststep {
    border: 1px solid #bcbcbf;
    border-radius: 10px;
    padding: 30px 20px;
    text-align: center;
}

.Customer-card-body .card-body .form-group label {
    font-weight: 500;
}
</style>